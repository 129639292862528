body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  line-height: 1.5em;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.slick-prev:before,
.slick-next:before {
  color: #1890ff !important;
}
.landingPage-main-title-container {
  margin-top: -30px;
}
/* *******************
  DELIVERYDATES
**********************/

.deliverydates_container {
  width: 1000px;
  margin: 3rem auto;
}

/* ****************
  NOTIFICATIONS
*******************/
.notifications_container {
  width: 800px;
  margin: 3rem auto;
}

/* *******************
  ALL DELIVERY TYPES
**********************/
.delivery_type {
  width: 700px;
  margin: 4rem auto !important;
}

/* ******************
  HISTORY
*********************/
.history_container {
  width: 800px;
  margin: 3rem auto;
}

/* ****************************
  center element inside screen 
*******************************/

.center1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  font-size: 30px;
}

/* ************************
    USER PROFILE
***************************/

.user_profile_container {
  margin-top: 1rem;
  margin-left: 2px;
  width: 200px;
}

.user_profile_img {
  width: 200px;
  border: 1px solid #e8e8e8;
}

.user_profile_info_container {
  margin-top: 1rem;
  border: 1px solid #e8e8e8;
  padding: 10px;
}

/* *****************
  REGISTER FROM
********************/

.regiter-from-cntainer,
.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10% auto;
}

.register-card {
  background-color: #1890ff !important;
  color: white !important;
  font-weight: bold;
}

.register-form {
  text-align: center;
  max-width: 400px !important;
  margin: 10px !important;
}

.google-button-container{
  margin-top: 5px;
  margin-bottom: 5px;
}
/* *********************
    LOGIN FORM
************************/

.login-form {
  text-align: center;
  width: 350px;
  margin: 10px;
  margin-bottom: 2px !important;
}

.google-button {
  color: white;
  font-weight: bold;
  width: 350px;
  margin-bottom: 2px;
}

/*********************************************************** 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
**************************************************************/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    width: 100%;
    margin: 2px;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
  */
  td:before {
    content: attr(data-label);
  }

  .deliverydates_container,
  .notifications_container,
  .delivery_type,
  .history_container {
    width: 90%;
  }

  .register-form,
  .login-form {
    width: 80%;
    margin-top: 5% !important;
  }
  .google-button-container{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .google-button {
    width: 80%;
  }
}

@media only screen and (max-width: 940px) {
  .user_profile_container {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
  }
  .user_profile_mobile_view_header {
    margin-top: 0 !important;
    height: max-content;
    background-color: white;
  }
  .user_profile_img {
    max-width: 150px;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
  }
  .user_profile_info_container {
    margin-top: 1rem;
    padding: 10px;
    border: none;
  }
}

/************************
  PRODUCT DETAILS
*************************/
.product-btns-container{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 25rem;
  height: 6rem;
}

/**********************
  DELIVERY PROFILE
***********************/



/**********************
  MOBILE BANNER
***********************/
.theme-bg {
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
  background: none;
}

.banner-mobile-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
  width: 100%;
}

/***************************
    WEB BANNER
****************************/
.banner-text-web{
  font-size: 35px;
  font-weight: 600;
}

.banner-content-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
  width: 50%;
  
}

.banner-btns-container{
  display: flex;
  justify-content: center;
}

.banner-btn{
  margin: 20px;
  font-size: 20px !important;
  font-weight: 600 !important;
  width: max-content;
}

@media screen and (max-width: 768px) {
  .banner-btns-container {
    display: flex;
    justify-content: flex-end;
  }

  .banner-btn {
    margin: 5px;
    font-size: 14px !important;
    font-weight: 600 !important;
    width: fit-content;
  } 
}

/*********************************
  Prelauch product show examples
**********************************/
.prelauch-productshow{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.explanation-card-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
  width: 80%;

}
.card-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.faq-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  margin: 10%;
  
}

@media screen and (max-width: 768px) {

  .faq-container {
    margin: 10% 0;
  }
  
}

/****************************
message shape 
****************************/
.message-container {
  max-width: 300px;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  position: relative;
}

.message-content {
  font-size: 16px;
  margin: 0;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.arrow.left {
  border-width: 10px 15px 10px 0;
  border-color: transparent #f0f0f0 transparent transparent;
  left: -15px;
  top: 10px;
}

.arrow.right {
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #f0f0f0;
  right: -15px;
  top: 10px;
}


/**********************
  TYPING ANIMATION
**********************/
.typing-animation {
  display: inline-block;
  vertical-align: bottom;
  font-size: 24px;
  color: #555;
}

.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #555;
  border-radius: 50%;
  margin-right: 2px;
  opacity: 0;
  animation: typing 2s infinite;
}

@keyframes typing {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.dot:nth-child(2) {
  animation-delay: 500ms
}

.dot:nth-child(3) {
  animation-delay: 1000ms
}

/*********************
    CONTACT US BUTTON
**********************/

.contact-us-button {
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom */
  right: 20px; /* Adjust the distance from the right */
  background-color: #e8f1f8;
  color: #3a3939; /* Text color */
  border: none;
  border-radius: 50%; /* Make it round */
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000; /* Ensure it's above other elements */
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.583); 
}

.contact-us-button:hover {
  background-color: #3e91f7;
} 

.whatsapp-icon {
  width: 24px; /* Adjust the size of the icon */
  height: auto;
  margin-right: 5px; /* Add spacing between icon and text */
}

/*************************
  CHAT CONTAINER STYLES
 ************************/
.chat-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  height: 400px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.chat-header {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.chat-close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.chat-body {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

/***************************
  MOBILE BOTTOM NAVBAR
****************************/
.mobile-nav-bar {
  display: none; /* Hide by default */
}

@media (max-width: 767px) {
  .mobile-nav-bar {
    display: block; /* Show only for screens smaller than 768px (mobile) */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff; 
    padding: 10px 0;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1); 
    text-align: center; 
  }
}

.mobile-nav-bar .bottom-navbar-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-nav-bar .bottom-navbar-icon-container .bottom-navbar-text {
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-align: center; /* Center the text horizontally */
  margin-top: 4px;
}

.mobile-nav-bar-link {
  color: #595959;
}

/******************
  ABOUT US
*******************/

.about-us-container {
  font: 1.5em sans-serif;
  line-height: 1.5em;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
}

.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
}

@media screen and (max-width: 768px) {
  .about-us-section {
    width: 90%;
  }
  .about-us-container {
    margin-top: 5%;
    font-size: medium;
  }
  
}

/* *****************
  Products dashbord
*********************/
.delete-product-modal-container{
  display: flex;
  justify-content: space-around;
  
}

.product-to-delete-info-container{
  display: flex;
  flex-direction: column;
  font-size: medium;
  font-weight: bold;

  
}

.product-to-delete-info-element{
  margin: 5px;
}

.PhoneInputCountryIconImg {
  width: 25px !important;
  height: 25px !important;
}

/* *****************
  user info verification
*********************/
.user-info-verification-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
}

.user-info-verification-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 20px;
  font-size: medium;
  font-weight: bold;

}

.user-info-verification-section .user-info-verification-section-element{
  margin: 10px;
}

/* Ensure the icons are always visible on mobile devices */
@media (max-width: 768px) {
  .ant-upload-list-item-actions {
    opacity: 1 !important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.739) !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: ' ';
  }
}

/************************
  PAYMENT INFO PAGE
*************************/
.payment-info-img{
  width: 300px;
}

@media screen and (max-width: 768px) {
  .payment-info-img {
    width: 250px;
  }
  
}

/**********************************
  Publishing a new product guide
***********************************/
/* Container styling */
.tutorial-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333; 
}

/* Heading styling */
.tutorial-container h2, h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #222; 
}

/* Paragraph styling */
.tutorial-container p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333; /* Medium dark color for paragraphs */
}

/* Ordered list styling */
.tutorial-container ol {
  padding-left: 20px;
}

/* List item styling */
.tutorial-container li {
  margin-bottom: 10px;
  font-size: 16px; /* Ensure list items have a readable font size */
}

/* Strong text styling */
.tutorial-container strong {
  color: #000; /* Strong text should be very dark for emphasis */
}

.example-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.example-images img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.example-images .example-image {
  max-width: 500px;
}

.tutorial-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tutorial-container h1, h2{
    word-wrap: break-word; /* Break long words */
    overflow-wrap: break-word; /* Break long words */
    white-space: normal; /* Allow text to wrap */
    line-height: 1.2; /* Adjust line height for better readability */
    font-size: 1.2em; /* Smaller font size for mobile */
  }
}

/*******************
      FOOTER
********************/
.footer-container {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: grey;
  padding: 0 20px;
  box-sizing: border-box;
}

.footer-container p {
  margin: 0 10px;
}

.footer-container a {
  color: grey;
  text-decoration: none;
}

/* .footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-container h4 {
  margin-bottom: 10px;
}

.footer-container ul {
  list-style: none;
  padding: 0;
}

.footer-container ul li {
  margin-bottom: 5px;
}

.footer-container ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-container .social-icons a {
  font-size: 24px;
} */

/*****************
  IFRAME STYLES
******************/

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.full-width-select {
  width: 100%;
}

/************************
  Pricing and payment page
*************************/
.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  padding: 20px;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plan-details {
  text-align: center;
  margin-top: 20px;
}

.plan-details h2 {
  font-size: 24px;
  color: #333;
}

.price {
  font-size: 20px;
  color: #e91e63;
  margin: 10px 0;
}

.benefits {
  font-size: 16px;
  color: #666;
}
.benefits-list {
  list-style-type: none;
  padding: 0;
  align-items: start;
  text-align: left;
  line-height: 30px;
}

.price-tag {
  font-size: 16px;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-tag p {
  margin: 10px;
}

.features-title {
  width: 100%;
  text-align: left;
}

/*********************
  STEPS CONTAINER
**********************/
.steps-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}

/*********************
  PRICIING PAGE
**********************/
.pricing-page {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.banner-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f8f9fa;
  text-align: center;
  padding: 50px 20px;
}

.banner-section img {
  max-width: 500px;
  max-height: 500px;
}

.banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  
}
.banner-text p {
  font-size: 1.2em;
  margin-bottom: 20px;
  width: 80%;
}
.banner-section h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.cta-button:hover {
  background-color: #0056b3;
}

.pricing-section {
  background-color: #ffffff;
  padding: 50px 20px;
  text-align: center;
}

.pricing-section h2 {
  margin-bottom: 20px;
}

.pricing-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  width: 30%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
}

.pricing-card h3 {
  margin-bottom: 15px;
}

.pricing-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.features-section {
  margin: 50px 0;
}

.features-section h2 {
  text-align: center;
  margin-bottom: 30px;
}

.feature-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  width: 30%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;

}


.feature-card h3 {
  margin-bottom: 15px;
}

.feature-card p {
  flex-grow: 1;
}
.feature-card button {
  margin-top: 20px;
}
.feature-icon {
  width: 300px;
  height: 300px;
  margin-bottom: 15px;
}

.testimonials-section {
  background-color: #f8f9fa;
  padding: 50px 20px;
}

.testimonials-section h2 {
  text-align: center;
  margin-bottom: 30px;
}

.testimonials {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.testimonial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  width: 30%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
}

.contact-us-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 20px;
  
}

.contact-details-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap
  
}
.contact-us-img {
  width: 400px;
  height: 400px;
  margin-right: 100px;
}

.contact-details {
  text-align: left;
}

.contact-details h2 {
  margin-bottom: 10px;
}

.contact-details p {
  margin-bottom: 5px;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .banner-text {
    width: 100%;
  }

  .banner-section img {
    max-width: 100%;
    max-height: 100%;
  }

  .pricing-card,
  .feature-card,
  .testimonial {
    width: 100%;
    max-width: 100%;
  }

  .contact-us-section {
    flex-direction: column;
  }

  .contact-us-icon {
    margin-bottom: 20px;
  }

  .contact-details {
    text-align: center;
  }

  .contact-us-img {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .contact-details-container {
    flex-direction: column;
    align-items: center;
  }
}

/* *****************
LISTING FEE PAGE.
********************/

.listing-fee-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f0f2f5; /* Light background color */
}

.payment-card {


  width: 100%;
  max-width: 400px; /* Maximum width of the card */
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for the card */
}

.listing-fee-price-tag {
  display: flex;
  justify-content: center;
  align-items: center;

}

.listing-fee-price-tag p {
  margin: 5px;
}

/*********************
  UPLOAD PRODUCT PAGE
**********************/

.upload-product-container {
 max-width: 700px;
  margin: 2rem auto;
}

@media screen and (max-width: 768px) {
  .upload-product-container {
    width: 90%;
  }
  
}